.blog-post{
    text-align: center;
    margin: 15px;
    border: 1px solid rgb(188, 120, 11);
    border-radius: 5px;
    padding: 10px;
}

.title {
    text-decoration: underline;
}
.content {
    padding: 5px;

}
.username {
    font-size: .9em;
    font-style: italic;
}
.id {
    font-size: .8em;
    text-align: right;
}