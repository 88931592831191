* {
  padding: 0;
  margin: 0;
}
#root {
  height: 100%;
}
.container {
  height: 100%;;

}
.App {
  margin: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 500px;
  max-width: 800px;
}
.body-container {
  background-color: #fbe7c0;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 2;
  min-height: 900px;
}