header {
    background-color: #ee894e;
    height: 50px;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid black;
}
.header-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.header-container p {
    margin: 20px;
}